<template>
  <div class="object-viewer-container">
    <ModelViewerWrapper :object="object" />
    <div class="action-buttons">
      <button class="action-button download-button" @click="downloadModel">
        <img src="@/assets/icons/download-icon.svg" alt="Download" />
        <span class="button-label">Download</span>
      </button>
      <button class="action-button share-button" @click="shareModel">
        <img src="@/assets/icons/share-icon.svg" alt="Share" />
        <span class="button-label">Share</span>
      </button>
    </div>
  </div>
</template>

<script>
import ModelViewerWrapper from '@/components/ModelViewerWrapper.vue';

export default {
  name: 'ObjectViewer',
  components: {
    ModelViewerWrapper,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async shareModel() {
      const modelId = this.object.id;
      const clientId = this.getClientId();
      const shareUrl = `${window.location.origin}/public/${clientId}_${modelId}`;

      const shareData = {
        title: 'Check out this 3D model',
        text: 'I wanted to share this 3D model with you!',
        url: shareUrl,
      };

      if (navigator.share) {
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.error('Error sharing:', err);
        }
      } else {
        this.copyToClipboard(shareUrl);
        alert('Link copied to clipboard. You can share it now.');
      }
    },
    getClientId() {
      let clientId = localStorage.getItem('client_id');
      if (!clientId) {
        clientId = Math.random().toString(36).substring(2, 11);
        localStorage.setItem('client_id', clientId);
      }
      return clientId;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          console.log('Link copied to clipboard');
        },
        (err) => {
          console.error('Could not copy Link: ', err);
        }
      );
    },
    downloadModel() {
      const link = document.createElement('a');
      link.href = this.object.glb_url;
      link.download = `model-${this.object.id}.glb`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.object-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}

/* Action Buttons Container */
.action-buttons {
  display: flex;
  gap: 20px;
  margin: 8px;
}

/* Individual Action Button */
.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: #fff;
  height: 60px;
  width: 150px;
  border: 2px solid;
  border-radius: 13px;
  color: #000;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Download Button Styles */
.download-button {
  border-color: #252D97;
}

.download-button img {
  filter: brightness(0) saturate(100%) invert(26%) sepia(10%) saturate(7075%) hue-rotate(209deg) brightness(89%) contrast(117%);
}

.download-button .button-label {
  color: #252D97;
}

.download-button:active {
  background-color: #252D97;
  transform: scale(0.98);
}

.download-button:active img {
  filter: invert(100%);
}

.download-button:active .button-label {
  color: #fff;
}

/* Share Button Styles */
.share-button {
  border-color: #8A2BE2;
}

.share-button img {
  filter: brightness(0) saturate(100%) invert(19%) sepia(100%) saturate(3758%) hue-rotate(267deg) brightness(91%) contrast(94%);
}

.share-button .button-label {
  color: #8A2BE2;
}

.share-button:active {
  background-color: #8A2BE2;
  transform: scale(0.98);
}

.share-button:active img {
  filter: invert(100%);
}

.share-button:active .button-label {
  color: #fff;
}

.button-label {
  display: inline-block;
  margin: 0 auto;
}

.action-button img {
  width: 20px;
  height: 20px;
}
</style>