<template>
  <div class="camera-app">
    <div class="top-bar">
      <div class="info-button" @click="showInfoView = true">
        <img src="@/assets/icons/info.svg" alt="Info" />
      </div>
    </div>
    <div class="camera-view">
      <CameraView ref="camera"></CameraView>
      <div ref="flashOverlay" class="flash-overlay"></div>
      <div ref="thumbnails" class="thumbnails scrollable">
        <ThumbnailView v-for="object in objects" :key="object.id" :object="object" 
          @delete="deleteObjectHandler"
          @view-model="openModelViewer" 
        />
      </div>
    </div>
    <div class="bottom-bar">
      <button class="all-models-button" @click="showModelListView = true">
        <img src="@/assets/icons/stack.svg" alt="All Models" />
        All Models
      </button>
      <div class="capture-button" @click="captureObject"></div>
    </div>
    <transition name="slide-up">
      <div v-if="showModelListView" class="overlay" @click="closeModelListView">
        <ModelListView
          :objects="objects"
          @delete="deleteObjectHandler"
          @close="closeModelListView"
          @view-model="openModelViewer"
          @click.stop
        />
      </div>
    </transition>
    <DrawerView v-model:visible="showModelViewer">
      <ObjectViewer :object="modelViewerObject" />
    </DrawerView>
    <transition name="fade">
      <div v-if="showInfoView" class="overlay" @click="closeInfoView">
        <InfoView @close="closeInfoView" @click.stop />
      </div>
    </transition>
  </div>
</template>

<script>
import CameraView from '../components/CameraView.vue';
import ThumbnailView from '../components/ThumbnailView.vue';
import ModelListView from './ModelListView.vue';
import DrawerView from '../components/DrawerView.vue';
import ObjectViewer from '@/components/ObjectViewer.vue';
import InfoView from './InfoView.vue';
import { getObjects, addObject, updateObject, deleteObject } from '../indexedDb';

export default {
  components: {
    CameraView,
    ThumbnailView,
    ModelListView,
    DrawerView,
    ObjectViewer,
    InfoView
  },
  data() {
    return {
      clientId: this.getClientId(),
      objects: [],
      showModelListView: false,
      showModelViewer: false,
      modelViewerObject: null,
      showInfoView: false,
    };
  },
  methods: {
    async captureObject() {
      // Don't try using ImageCapture API here, as it's not supported on iOS
      // https://developer.mozilla.org/en-US/docs/Web/API/ImageCapture
      const video = this.$refs.camera.$refs.video;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL('image/png');

      if (!image || image === 'data:,' || image.length < 5000) {
        alert('Could not take the photo. This is a known camera permissions bug. Please try again, the camera should work now.');
        return;
      }

      // Trigger flash effect and haptic feedback
      this.triggerFlash();
      this.triggerHapticFeedback();

      const object = {
        id: Date.now(),
        thumbnail: image,
        label: 'Processing...',
        progress: 0,
        timestamp: new Date().toISOString(),
        localUrl: null,
        url: null,
        fakeProgressInterval: null
      };
      this.objects.push(object);
      await addObject(object);

      this.analytics.track('Capture Started', {
        objectId: object.id,
        timestamp: new Date().toISOString(),
      });''

      const coordinates = { x: canvas.width / 2, y: canvas.height / 2 };
      const format = this.isIOS() ? 'usdz' : 'gltf';
      this.sendToBackend(image, coordinates, object.id, format);

      // scroll to the last thumbnail if necessary
      this.$nextTick(() => {
      const thumbnails = this.$refs.thumbnails;
      const lastThumbnail = thumbnails.lastElementChild;
      lastThumbnail.classList.add('pulse');
      thumbnails.scrollLeft = thumbnails.scrollWidth;
    });
    },
    triggerFlash() {
      const flash = this.$refs.flashOverlay;
      flash.style.display = 'block';
      setTimeout(() => {
        flash.style.display = 'none';
      }, 100); // Show the flash for 100ms
    },
    triggerHapticFeedback() {
      if (navigator.vibrate) {
        navigator.vibrate(200); // Vibrate for 200ms
      }
    },
    startFakeProgress(objectId, estimatedTime) {
      const object = this.objects.find(obj => obj.id === objectId);
      if (!object) return;

      const intervalTime = (estimatedTime * 1000) / 90; // estimatedTime to reach 90%
      object.fakeProgressInterval = setInterval(() => {
        if (object.progress < 90) {
          object.progress += 1;
          this.$forceUpdate();
        } else {
          clearInterval(object.fakeProgressInterval);
        }
      }, intervalTime);
    },
    async sendToBackend(image, coordinates, id, format, retries = 3) {
      try {
        const blob = await fetch(image).then(res => res.blob());
        const formData = new FormData();
        formData.append('image', blob, 'capture.png');
        formData.append('coordinates', JSON.stringify(coordinates));
        formData.append('session_id', id);
        formData.append('client_id', this.clientId);
        formData.append('format', format);

        const response = await fetch('https://oneshot-inference.nuvo3d.com:8000/process/', {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.startFakeProgress(id, data.estimated_time);
        this.pollForUpdates(id);
      } catch (error) {
        console.error('Error in sendToBackend:', error);
        if (retries > 0) {
          console.log(`Retrying... (${retries} retries left)`);
          await this.sendToBackend(image, coordinates, id, format, retries - 1);
        } else {
          alert('An error ocurred in the server. Please try again.');
          this.deleteObjectHandler(id);
        }
      }
    },
    async pollForUpdates(id, interval = 10000) {
      const poll = async () => {
        try {
          const response = await fetch(`https://oneshot-inference.nuvo3d.com:8000/status/${id}`, {
            method: 'GET'
          });

          const data = await response.json();
          const object = this.objects.find(obj => obj.id === id);

          if (data.status === 'finished' && object) {
            clearInterval(object.fakeProgressInterval);
            object.progress = 100;
            console.log(`Capture completed: ${data.glb_url}`);
            object.url = data.model_url;
            object.usdz_url = data.usdz_url;
            object.glb_url = data.glb_url;
            object.label = 'Completed';
            await updateObject(object, object.id);
            this.$forceUpdate();
            this.analytics.track('Capture Completed', {
              objectId: object.id,
              duration: (new Date() - new Date(parseInt(object.id, 10))) / 1000,
              objectUrl: object.url,
            });
          } else if (data.status === 'failed') {
            alert("There was a problem processing this capture.");
            this.analytics.track('Capture Failed', {
              objectId: object.id,
              error: data.error,
            });
            this.deleteObjectHandler(id);
          } else if (data.status === 'invalid') {
            this.analytics.track('Capture Invalid', {
              objectId: object.id,
              error: data.error,
            });
            this.deleteObjectHandler(id);
          } else if (data.status === 'processing') {
            setTimeout(poll, interval); // Poll every specified interval
          }
        } catch (error) {
          console.error('Error polling update:', error);
        }
      };
      poll();
    },
    deviceType() {
      if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
        return 'iOS';
      } else if (/Mac OS X(?!.*iPhone)/.test(navigator.userAgent)) {
        return 'MacOS';
      } else if (navigator.userAgent.includes('Android')) {
        return 'Android';
      } else {
        return 'Others';
      }
    },
    isIOS() {
      console.log(`The device type is: ${this.deviceType()}`);
      return this.deviceType() === 'iOS' || this.deviceType() === 'MacOS';
    },
    async loadObjects() {
      let objects = await getObjects();
      const loadedObjects = [];

      for (const object of objects) {
        if (object.url && object.progress === 100) {
          loadedObjects.push(object);
        } else {
          await deleteObject(object.id);
        }
      }
      this.objects = loadedObjects;
    },
    async deleteObjectHandler(id) {
      await deleteObject(id);
      this.objects = this.objects.filter(object => object.id !== id);
      this.analytics.track('Object Deleted', { 
        objectId: id,
      });
    },
    closeModelListView() {
      this.showModelListView = false;
      this.analytics.track('Model List View Closed');
    },
    closeInfoView() {
      this.showInfoView = false;
      this.analytics.track('Info View Closed');
    },
    getClientId() {
      let clientId = localStorage.getItem('client_id');
      if (!clientId) {
        clientId = Math.random().toString(36).substring(2, 11);
        localStorage.setItem('client_id', clientId);
      }
      return clientId;
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.analytics.track('App Left');
      } else {
        this.analytics.track('App Returned');
      }
    },
    openModelViewer(object) {
      this.modelViewerObject = object;
      this.showModelViewer = true;
      this.trackOpenObject(object);
    },
    trackOpenObject(object) {
      this.analytics.track('Object Viewed', { 
        objectId: object.id,
      });
    },
  },
  async mounted() {
    await this.loadObjects();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    // autoshow the info view only the first time
    if (!localStorage.getItem('infoViewShown')) {
      this.showInfoView = true;
      localStorage.setItem('infoViewShown', 'true');
    }
    this.analytics.identify(this.clientId, {
      deviceType: this.deviceType(),
      userAgent: navigator.userAgent,
    });
    this.analytics.page('Main View');
  },
  beforeUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Disable scrolling */
  font-family: 'Montserrat', sans-serif; /* Change the font */
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  display: flex;
  background-color: black;
  flex-direction: column;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  box-sizing: border-box;
  overflow: hidden; /* Disable scrolling within the camera-app */
}

.flash-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
  z-index: 1000;
  display: none;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.top-bar {
  height: 10svh;
  display: flex;
  justify-content: flex-end; /* Align objects to the right */
  align-items: center;
  background-color: black;
  color: white;
  font-size: 1em;
  padding: 0 10px;
}

.info-button {
  background: none;
  font-size: 1.5em;
  cursor: pointer;
}

.info-button img {
  width: 35px;
  height: 35px;
  filter: brightness(0) saturate(100%) invert(100%);
}

.camera-view {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the video covers the entire area without distortion */
}

.bottom-bar {
  height: 15svh;
  display: flex;
  justify-content: center; /* Center the capture button */
  align-items: center;
  background-color: black;
  position: relative;
  padding: 0 10px; /* Add padding to the left and right */
}

.all-models-button {
  color: #FFD700; /* Darker yellow background */
  border-color: #FFD700; /* Darker yellow border */
  background: none;
  border-width: 1px; /* Add border width */
  font-size: 1em; /* Adjust font size if needed */
  cursor: pointer;
  position: absolute; /* Position the all models button */
  left: 5vw; /* Align it to the left */
  height: 45px;
  padding: 10px 10px; /* Add padding */
  border-radius: 12px; /* Add border radius */ 
  display: flex;
  border-style: solid; /* Add border style */
  align-items: center; /* Align text vertically to the center */
}

.all-models-button img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  filter: brightness(0) saturate(100%) invert(79%) sepia(86%) saturate(2474%) hue-rotate(352deg) brightness(97%) contrast(112%);
}

.capture-button {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Center the capture button */
  left: 50%;
  transform: translateX(-50%);
}

.capture-button::after {
  content: ' ';
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
  border: 2.5px solid black;
}

.thumbnails {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  max-width: calc(100vw - 20px);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.thumbnails::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.thumbnails {
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.3s ease;
}

.slide-up-enter-from, .slide-up-leave-to /* .slide-up-leave-active for <2.1.8 */ {
  transform: translateY(100%);
}

.slide-up-enter-to, .slide-up-leave-from /* .slide-up-enter-active for <2.1.8 */ {
  transform: translateY(0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.model-list-view {
  overflow-y: auto;
}

.pulse {
  animation: pulse-animation 1s;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
